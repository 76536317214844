import { Input, DatePicker, message } from "antd";
import { SearchOutlined, CalendarOutlined } from "@ant-design/icons";

export const API_VERSION = "1.2.8";

const returnGeneratePassword = () => {
  const length = 12;
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
  let generatedPassword = "";
  for (let i = 0; i < length; i++) {
    generatedPassword += charset.charAt(
      Math.floor(Math.random() * charset.length)
    );
  }

  return generatedPassword;
};

const copyText = (txt) => {
  if (txt) {
    navigator.clipboard
      .writeText(txt)
      .then(() => {
        message.success("Copied to clipboard!");
      })
      .catch((err) => {
        message.error("Failed to copy!");
      });
  } else {
    message.warning("No copy!");
  }
};

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const getColumnDateProps = (onChange) => ({
  filterDropdown: ({}) => (
    <div
      style={{
        padding: 8,
      }}
    >
      <DatePicker.RangePicker
        onChange={(e, dateString) => onChange(dateString)}
        renderExtraFooter={() => "extra footer"}
      />
    </div>
  ),
  filterIcon: (filtered) => (
    <CalendarOutlined
      style={{
        color: filtered ? "#1890ff" : undefined,
      }}
    />
  ),
});

const parseDate = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const hours = d.getHours();
  let month = d.getMonth() + 1;
  let minutes = d.getMinutes();
  let day = d.getDate();

  if (day < 10) {
    day = `0${day}`;
  }

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  if (month < 10) {
    month = `0${month}`;
  }

  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

const parseDateFormat = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const hours = d.getHours();
  let month = d.getMonth() + 1;
  let minutes = d.getMinutes();
  let day = d.getDate();

  if (day < 10) {
    day = `0${day}`;
  }

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  if (month < 10) {
    month = `0${month}`;
  }

  return `${day}/${month}/${year}`;
};

const getQueryDate = (date, type = "createdAt") => {
  if (date && date[0]) {
    let to = new Date(date[1]);
    to.setDate(to.getDate() + 1);

    return {
      [type]: {
        from: new Date(date[0]),
        to: new Date(to),
      },
    };
  }

  return null;
};

const getColumnSearchProps = (_ref) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
    return (
      <Input
        ref={_ref}
        allowClear
        style={{
          position: "absolute",
          right: "18px",
          bottom: "40px",
          width: "180px",
        }}
        placeholder="Type text here"
        value={selectedKeys[0]}
        onChange={(e) => {
          const value = e.target.value.trim();
          setSelectedKeys(value ? [value] : []);
          confirm({ closeDropdown: false });
        }}
      />
    );
  },
  filterIcon: () => {
    return (
      <>
        <SearchOutlined />
      </>
    );
  },

  onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() => _ref?.current?.select(), 100);
    }
  },
});

export {
  getBase64,
  getColumnDateProps,
  parseDate,
  getQueryDate,
  getColumnSearchProps,
  parseDateFormat,
  returnGeneratePassword,
  copyText,
};
